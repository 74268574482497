import { generateMapByOpts } from 'common/utils'

// 状态
const statusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '正常',
    value: 1
  },
  {
    text: '关闭',
    value: 0
  }
]
//标签层级
const labelLevelOps=[
  {
    text: '全部',
    value: undefined
  },
  {
    text: '平台',
    value: 106
  },
  {
    text: '公司',
    value: 102
  },
  {
    text: '项目',
    value: 101
  }
]
// 服务范围
const serviceScopeOpts = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '周边范围',
    value: 1
  },
  {
    text: '行政范围',
    value: 2
  },
  {
    text: '项目内业主',
    value: 3
  }
]

// 物流方式
const deliveryTypeOpts = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '无物流',
    value: 0
  },
  {
    text: '快递配送',
    value: 1
  },
  {
    text: '预约上门',
    value: 2
  },
  {
    text: '用户自提',
    value: 3
  }
]

// 门店状态
const storeStatusOpts = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '正常',
    value: 0
  },
  {
    text: '关闭',
    value: 1
  }
]

// 商品状态
const productStatusOpts = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '已上架',
    value: 0
  },
  {
    text: '已下架',
    value: 1
  }
]

// 实名认证
const realNameOpts = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '待认证',
    value: 0
  },
  {
    text: '已认证',
    value: 1
  }
]

// 身份
const userTypeOpts = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '业主',
    value: 1
  }, {
    text: '家属',
    value: 2
  }, {
    text: '租户',
    value: 3
  }
]

// 注册用户
const userSourceOpts = [{
  text: 'app注册用户',
  value: '1'
}]

const statusMap = generateMapByOpts(statusOps)
const serviceScopeMap = generateMapByOpts(serviceScopeOpts)
const deliveryTypeMap = generateMapByOpts(deliveryTypeOpts)
const storeStatusMap = generateMapByOpts(storeStatusOpts)
const productStatusMap = generateMapByOpts(productStatusOpts)
const realNameMap = generateMapByOpts(realNameOpts)

export {
  labelLevelOps,
  statusOps,
  statusMap,
  serviceScopeOpts,
  serviceScopeMap,
  deliveryTypeOpts,
  deliveryTypeMap,
  storeStatusOpts,
  storeStatusMap,
  productStatusOpts,
  productStatusMap,
  realNameOpts,
  realNameMap,
  userTypeOpts,
  userSourceOpts
}
