var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ApplicationLibraryTenantEditForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "应用信息" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "应用名称",
                        rules: [
                          {
                            required: true,
                            message: "请输入应用名称",
                            trigger: "change",
                          },
                          { validator: _vm.validateAppName, trigger: "blur" },
                        ],
                        prop: "customName",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请填写最多5个汉字或10个英文/数字",
                          maxlength: 10,
                          width: 300,
                        },
                        model: {
                          value: _vm.form.customName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "customName", $$v)
                          },
                          expression: "form.customName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "应用图标",
                        rules: [
                          {
                            required: true,
                            message: "请上传应用图标",
                            trigger: "change",
                          },
                        ],
                        prop: "subImg",
                      },
                    },
                    [
                      _c("v-uploader", {
                        attrs: {
                          action: _vm.uploadURL,
                          imgUrls: _vm.form.subImg,
                        },
                        on: {
                          "update:imgUrls": function ($event) {
                            return _vm.$set(_vm.form, "subImg", $event)
                          },
                          "update:img-urls": function ($event) {
                            return _vm.$set(_vm.form, "subImg", $event)
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "tip",
                            fn: function () {
                              return [
                                _c("span", [
                                  _vm._v(
                                    "注：图片要求：宽132px高132px，图片小于50K"
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "应用状态",
                        rules: [
                          {
                            required: true,
                            message: "请选择应用状态",
                            trigger: "change",
                          },
                        ],
                        prop: "subStatus",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.subStatusOps, width: _vm.width },
                        model: {
                          value: _vm.form.subStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "subStatus", $$v)
                          },
                          expression: "form.subStatus",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属版块",
                        rules: [
                          {
                            type: "array",
                            required: true,
                            message: "请选择所属版块",
                            trigger: "change",
                          },
                        ],
                        prop: "section",
                      },
                    },
                    [
                      _c("v-cascader", {
                        attrs: {
                          value: _vm.form.section,
                          options: _vm.sectionOps,
                          props: _vm.props,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.form, "section", $event)
                          },
                          change: _vm.sectionChange,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.subTopicTypeVisible
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "话题类型",
                            rules: [
                              {
                                required: true,
                                message: "请选择话题类型",
                                trigger: "change",
                              },
                            ],
                            prop: "subTopicType",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.subTopicTypeOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.subTopicType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "subTopicType", $$v)
                              },
                              expression: "form.subTopicType",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.isOrderService
                ? _c(
                    "col2-block",
                    { attrs: { title: "工单加签" } },
                    _vm._l(_vm.form.labelItemList, function (item, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          item.name
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "工单二级分类" } },
                                [
                                  _c("div", {
                                    domProps: {
                                      textContent: _vm._s(item.name),
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否开启工单加签",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择是否加签",
                                    trigger: "change",
                                  },
                                ],
                                prop: `labelItemList.${index}.isAdd`,
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.enableOps,
                                  width: _vm.width,
                                },
                                model: {
                                  value: item.isAdd,
                                  callback: function ($$v) {
                                    _vm.$set(item, "isAdd", $$v)
                                  },
                                  expression: "item.isAdd",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.isAdd === 1,
                                  expression: "item.isAdd === 1",
                                },
                              ],
                              attrs: {
                                label: "关联服务标签",
                                rules: [
                                  {
                                    required: item.isAdd === 1,
                                    message: "请选择关联服务标签",
                                    trigger: "change",
                                  },
                                ],
                                prop: `labelItemList.${index}.labels`,
                              },
                            },
                            [
                              _c("label-chose-panel", {
                                attrs: {
                                  serviceId: _vm.serviceId,
                                  list: item.labels,
                                },
                                on: {
                                  "update:list": function ($event) {
                                    return _vm.$set(item, "labels", $event)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }