<template>
  <div class="label-chose-panel-wrapper">
    <chosen-list-panel
      :list.sync="listInner"
      @select="onSelect"
      @change="onChange"
    ></chosen-list-panel>
    <multi-select
      title="工单加签"
      :isShow.sync="muiltSelectVisible"
      :searchUrl="searchUrl"
      :headers="headers"
      :searchParams="searchParams"
      :responseParams="responseParams"
      :responseKey="responseKey"
      :backFill.sync="listInner"
      :handleData="handleData"
      :append-to-body="true"
    >
      <template #searchSlot>
        <v-input label="标签名称" v-model="searchParams.labelName" />
        <v-input label="创建人" v-model="searchParams.inuser" />
        <v-datepicker label="创建时间" :startTime.sync="searchParams.createStartTime" :endTime.sync="searchParams.createEndTime" :appendToBody="false" />
      </template>
    </multi-select>
  </div>
</template>
<script>
import { ChosenListPanel, MultiSelect } from 'components/bussiness'
import { queryLabelListURL } from './api'
import { statusMap } from 'views/common/labelManage/map'
export default {
  name: 'LabelChosePanel',
  props: {
    list: {
      type: Array
    },
    serviceId: [String, Number]
  },
  components: {
    ChosenListPanel,
    MultiSelect
  },
  created () {
    this.searchParams.labelType = this.serviceId
  },
  data () {
    return {
      listInner: [],
      muiltSelectVisible: false,
      searchUrl: queryLabelListURL,
      headers: [
        {
          prop: 'labelName',
          label: '标签名称'
        },
        {
          prop: 'category',
          label: '分类'
        },
        {
          prop: 'statusText',
          label: '状态'
        },
        {
          prop: 'inuser',
          label: '创建人'
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ],
      searchParams: {
        labelName: '',
        inuser: '',
        createStartTime: '',
        createEndTime: '',
        labelType: ''
      },
      responseParams: {
        id: 'id',
        name: 'labelName'
      },
      responseKey: {
        id: 'id',
        name: 'text'
      }
    }
  },
  methods: {
    handleData (data) {
      data.statusText = statusMap[data.status]
    },
    onSelect () {
      this.muiltSelectVisible = true
    },
    onChange (list) {
      this.$emit('update:list', list)
    }
  },
  watch: {
    'list': function (newVal, oldVal) {
      this.listInner = newVal
    },
    'serviceId': function (newVal) {
      this.searchParams.labelType = newVal
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
