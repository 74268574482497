<template>
  <div class="ApplicationLibraryTenantEditForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
        <col2-block title="应用信息">
          <el-form-item label="应用名称"
          :rules="[{ required: true, message: '请输入应用名称', trigger: 'change' },
          { validator: validateAppName, trigger: 'blur' }]"
          prop="customName">
            <v-input v-model="form.customName" placeholder="请填写最多5个汉字或10个英文/数字" :maxlength="10" :width="300" />
          </el-form-item>
          <el-form-item label="应用图标" :rules="[{ required: true, message: '请上传应用图标', trigger: 'change' }]" prop="subImg">
            <v-uploader :action="uploadURL" :imgUrls.sync="form.subImg">
              <template #tip>
                <span>注：图片要求：宽132px高132px，图片小于50K</span>
              </template>
            </v-uploader>
          </el-form-item>
          <el-form-item label="应用状态" :rules="[{ required: true, message: '请选择应用状态', trigger: 'change' }]" prop="subStatus">
            <v-select v-model="form.subStatus" :options="subStatusOps" :width="width" />
          </el-form-item>
          <el-form-item label="所属版块" :rules="[{ type: 'array', required: true, message: '请选择所属版块', trigger: 'change' }]" prop="section">
            <v-cascader :value.sync="form.section" :options="sectionOps" :props="props" @change="sectionChange"/>
          </el-form-item>
          <el-form-item v-if="subTopicTypeVisible" label="话题类型" :rules="[{ required: true, message: '请选择话题类型', trigger: 'change' }]" prop="subTopicType">
            <v-select v-model="form.subTopicType" :options="subTopicTypeOps" :width="width" />
          </el-form-item>
        </col2-block>
        <col2-block title="工单加签" v-if="isOrderService">
          <div v-for="(item, index) in form.labelItemList" :key="index">
            <el-form-item label="工单二级分类" v-if="item.name">
              <div v-text="item.name"></div>
            </el-form-item>
            <el-form-item label="是否开启工单加签" :rules="[{ required: true, message: '请选择是否加签', trigger: 'change' }]" :prop="`labelItemList.${index}.isAdd`">
              <v-select v-model="item.isAdd" :options="enableOps" :width="width"></v-select>
            </el-form-item>
            <el-form-item v-show="item.isAdd === 1" label="关联服务标签" :rules="[{ required: item.isAdd === 1, message: '请选择关联服务标签', trigger: 'change' }]" :prop="`labelItemList.${index}.labels`">
              <label-chose-panel :serviceId="serviceId" :list.sync="item.labels"></label-chose-panel>
            </el-form-item>
          </div>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { uploadURL } from 'common/api'
import { vUploader, vCascader } from 'components/control'
import { Col2Detail, Col2Block } from 'components/bussiness'
import { editGrantedURL, queryGrantedURL, querySecondCategoryURL, queryLabelConfigURL, queryServiceIDURL } from './api'
import { setSubStatusOps, setSubTopicTypeOps } from '../map'
import { getSectionOps } from '../common'
import LabelChosePanel from './LabelChosePanel'

export default {
  name: 'ApplicationLibraryTenantEditForm',
  components: {
    vUploader,
    vCascader,
    Col2Detail,
    Col2Block,
    LabelChosePanel
  },
  data () {
    return {
      width: 200,
      regionId: undefined,
      uploadURL: `${uploadURL}?module=icon`,
      subStatusOps: setSubStatusOps(),
      subTopicTypeOps: setSubTopicTypeOps(),
      sectionOps: [],
      subTopicTypeVisible: false,
      props: {
        children: 'options'
      },
      form: {
        id: '',
        customName: '',
        subImg: '',
        subStatus: undefined,
        section: [],
        subTopicType: undefined,
        labelItemList: []
      },
      submitConfig: {
        queryUrl: '',
        submitUrl: editGrantedURL,
        submitMethod: 'PUT'
      },
      enableOps: [
        {
          text: '是',
          value: 1
        },
        {
          text: '否',
          value: 2
        }
      ],
      subCode: '',
      isOrderService: 0,
      serviceId: ''
    }
  },
  async created () {
    this.$setBreadcrumb('编辑租户授权应用')
    let { data } = await this.$axios.get(queryServiceIDURL)
    this.serviceId = data
    console.log(this.serviceId)
  },
  mounted () {
    const { subId, regionId } = this.$route.query
    this.regionId = regionId
    this.submitConfig.queryUrl = `${queryGrantedURL}/${subId}`
    this.$refs.formPanel.getData()
  },
  methods: {

    // APP2-1306 应用名称支持10位字符APP2-1332
    validateAppName (rule, value, callback) {
      if (value && value.length > 0) {
        let regExp = /^[\u4e00-\u9fa5a-zA-Z0-9]+$/
        if (!regExp.test(value)) {
          callback(new Error('请填写最多5个汉字或10个英文/数字'))
        } else {
          let len = this.getByteLen(value)
          if (len > 10) {
            callback(new Error('请填写最多5个汉字或10个英文/数字'))
          } else {
            callback()
          }
        }
      } else {
        callback(new Error('请输入应用名称'))
      }
    },

    getByteLen (val) {
      var len = 0
      for (var i = 0; i < val.length; i++) {
        var a = val.charAt(i)
        // eslint-disable-next-line no-control-regex
        if (a.match(/^[\u4e00-\u9fa5]/ig) != null) {
          len += 2
        } else {
          len += 1
        }
      }
      return len
    },

    sectionChange (section) {
      if (section && section.length && section[1].sectionType === 1) {
        this.subTopicTypeVisible = true
      } else {
        this.subTopicTypeVisible = false
      }
    },
    async update (data) {
      Object.keys(this.form).forEach(key => {
        if (!['section', 'labelItemList'].includes(key)) {
          this.form[key] = data[key]
        }
      })
      this.sectionOps = await getSectionOps({
        params: {
          regionId: this.regionId
        }
      }, true)
      const firstLevel = data['parentSectionId']
      const secondLevel = data['sectionId']
      if (firstLevel && secondLevel) {
        let firstLevelIndex = this.sectionOps.findIndex((sectionOp) => {
          return sectionOp.value === firstLevel
        })
        if (firstLevelIndex === -1) {
          this.sectionOps.unshift({
            label: data['parentSectionName'],
            value: firstLevel,
            options: []
          })
          firstLevelIndex = 0
        }
        let secondLevelIndex = this.sectionOps[firstLevelIndex].options.findIndex((sectionOp) => {
          return sectionOp.value === secondLevel
        })
        if (secondLevelIndex === -1) {
          this.sectionOps[firstLevelIndex].options.unshift({
            label: data['sectionName'],
            value: secondLevel
          })
        }
        this.form['section'] = [firstLevel, secondLevel]
      }
      this.subCode = data.subCode
      this.isOrderService = data.isOrderService
      if (this.isOrderService) {
        this.querySecondCategory(data.subCode)
      }
    },
    submitBefore (data) {
      const section = data['section']
      if (section && section.length) {
        data['sectionId'] = section[1]
      }
      delete data['section']
      if (!this.subTopicTypeVisible) {
        data['subTopicType'] = undefined
      }
      data.labelConfigSaveF = {
        orderNumberSaveFS: this.form.labelItemList.map(item => {
          return {
            isOpen: item.isAdd === 1,
            labelIds: item.labels.map(label => label.id),
            orderNumer: item.id
          }
        }),
        regionId: this.regionId,
        subCode: this.subCode
      }
      delete data.labelItemList

      // 操作日志的数据对象参数
      let dataObject = `${data.customName}-${this.$route.query.regionName}`
      this.$set(data, 'dataObject', dataObject)

      return true
    },
    async querySecondCategory (subCode) {
      let params = {
        subCode: subCode
      }
      let { data } = await this.$axios.get(querySecondCategoryURL, {
        params: params
      })
      this.form.labelItemList = data.map(item => ({ ...item, labels: [], isAdd: undefined }))
      if (this.isOrderService && data.length === 0) {
        this.form.labelItemList = [
          {
            isAdd: 2,
            labels: [],
            id: ''
          }
        ]
      }
      this.queryLabelConfig(subCode, this.regionId)
    },
    async queryLabelConfig (subCode, regionId) {
      let { data } = await this.$axios.get(queryLabelConfigURL, {
        params: {
          subCode,
          regionId
        }
      })
      this.form.labelItemList.forEach(label => {
        // 如果有id，则需要找到对应orderNumber的二级分类,否则直接回显示条数
        if (label.id) {
          let target = data.find(item => item.orderNumer === String(label.id))
          if (target && target.labelIds && target.labelIds.length > 0) {
            label.labels = target.labelIds.map(item => ({ id: Number(item.id), text: item.name }))
            label.isAdd = 1
          } else {
            label.labels = []
            label.isAdd = 2
          }
        } else {
          label.labels = data[0].labelIds.map(item => ({ id: Number(item.id), text: item.name }))
          label.isAdd = 1
        }
      })
    }
  }
}
</script>
